<template>
  <KTCard :page="page">
    <template #body>
      <KTTable
        :fields="list.columns"
        :items="list.data"
        :primaryKey="list.primaryKey"
        :sortBy="list.sortBy"
        :paginated="false"
        :searchable="false"
      >
        <template #custom-foot>
          <tr class="bg-primary text-white" v-if="list.foot_data[0]">
            <td>Total ({{ list.foot_data[0].simbolo_moeda }})</td>
            <td></td>
            <td class="text-right">
              {{ formatCurrency(list.foot_data[0].valor_mensal) }}
            </td>
            <td class="text-right">
              {{ formatCurrency(list.foot_data[0].valor_anual) }}
            </td>
            <td class="text-right">-</td>
          </tr>
        </template>
      </KTTable>
    </template>
  </KTCard>
</template>

<script>
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipResumoInvestimentoDepreciacao",
  props: {
    depreciacao: {
      type: Array,
      required: true
    },
    depreciacao_total: {
      type: Array,
      required: true
    }
  },
  mixins: [HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-dollar-sign",
        title: "Depreciação Anual por Tipo de Investimento"
      },
      list_options: {
        columns: [
          {
            key: "descricao_tipo",
            label: "Tipo",
            sortable: false
          },
          {
            key: "simbolo_moeda",
            label: "Moeda",
            sortable: false,
            tdClass: "text-center"
          },
          {
            key: "valor_mensal",
            label: "Depreciação Mensal",
            sortable: false,
            tdClass: "text-right",
            formatter: "currency"
          },
          {
            key: "valor_anual",
            label: "Depreciação Anual",
            sortable: false,
            tdClass: "text-right",
            formatter: "currency"
          },
          {
            key: "percentual_depreciacao",
            label: "Taxa Anual (%)",
            sortable: false,
            tdClass: "text-right",
            formatter: "number"
          }
        ],
        primaryKey: "idpip_investimento_depreciacao",
        sortBy: ""
      }
    };
  },
  computed: {
    list() {
      return {
        ...this.list_options,
        data: this.depreciacao,
        foot_data: this.depreciacao_total
      };
    }
  }
};
</script>
