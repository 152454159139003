<template>
  <KTCard :page="page">
    <template #body>
      <KTTable
        :fields="list.columns"
        :items="list.data"
        :primaryKey="list.primaryKey"
        :sortBy="list.sortBy"
        :paginated="false"
        :searchable="false"
      >
        <template #custom-foot>
          <tr class="bg-primary text-white" v-if="list.foot_data[0]">
            <td>Total ({{ list.foot_data[0].simbolo_moeda }})</td>
            <td></td>
            <td class="text-right">
              {{
                formatCurrency(
                  list.foot_data[0]
                    .valor_investimento_fixo_financiamento_terceiros
                )
              }}
            </td>
            <td class="text-right">
              {{
                formatCurrency(
                  list.foot_data[0].valor_investimento_fixo_recursos_proprios
                )
              }}
            </td>
            <td class="text-right">
              {{
                formatCurrency(
                  list.foot_data[0]
                    .valor_investimento_pre_financiamento_terceiros
                )
              }}
            </td>
            <td class="text-right">
              {{
                formatCurrency(
                  list.foot_data[0].valor_investimento_pre_recursos_proprios
                )
              }}
            </td>
            <td class="text-right">
              {{ formatCurrency(list.foot_data[0].valor_total) }}
            </td>
            <td class="text-right">-</td>
          </tr>
          <tr class="bg-primary text-white" v-if="list.foot_data[0]">
            <td>Total (%)</td>
            <td></td>
            <td class="text-right">
              {{
                formatNumber(
                  list.foot_data[0]
                    .percentual_investimento_fixo_financiamento_terceiros
                )
              }}
            </td>
            <td class="text-right">
              {{
                formatNumber(
                  list.foot_data[0]
                    .percentual_investimento_fixo_recursos_proprios
                )
              }}
            </td>
            <td class="text-right">
              {{
                formatNumber(
                  list.foot_data[0]
                    .percentual_investimento_pre_financiamento_terceiros
                )
              }}
            </td>
            <td class="text-right">
              {{
                formatNumber(
                  list.foot_data[0]
                    .percentual_investimento_pre_recursos_proprios
                )
              }}
            </td>
            <td class="text-right">-</td>
            <td class="text-right">-</td>
          </tr>
        </template>
      </KTTable>
    </template>
  </KTCard>
</template>

<script>
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipResumoInvestimentoTipoInvestimento",
  props: {
    tipo_investimento: {
      type: Array,
      required: true
    },
    tipo_investimento_total: {
      type: Array,
      required: true
    }
  },
  mixins: [HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-dollar-sign",
        title: "Investimento Fixo e Pré-Operacional por Tipo de Investimento"
      },
      list_options: {
        columns: [
          {
            key: "descricao_tipo",
            label: "Tipo",
            sortable: false
          },
          {
            key: "simbolo_moeda",
            label: "Moeda",
            sortable: false,
            tdClass: "text-center"
          },
          {
            key: "valor_investimento_fixo_financiamento_terceiros",
            label: "Investimento Fixo - Financiamento de Terceiros",
            sortable: false,
            tdClass: "text-right",
            formatter: "currency"
          },
          {
            key: "valor_investimento_fixo_recursos_proprios",
            label: "Investimento Fixo - Recursos Próprios",
            sortable: false,
            tdClass: "text-right",
            formatter: "currency"
          },
          {
            key: "valor_investimento_pre_financiamento_terceiros",
            label: "Investimento Pré-Operacional - Financiamento de Terceiros",
            sortable: false,
            tdClass: "text-right",
            formatter: "currency"
          },
          {
            key: "valor_investimento_pre_recursos_proprios",
            label: "Investimento Pré-Operacional - Recursos Próprios",
            sortable: false,
            tdClass: "text-right",
            formatter: "currency"
          },
          {
            key: "valor_total",
            label: "Total",
            sortable: false,
            tdClass: "text-right",
            formatter: "currency"
          },
          {
            key: "percentual_total",
            label: "%",
            sortable: false,
            tdClass: "text-right",
            formatter: "number"
          }
        ],
        primaryKey: "tipo",
        sortBy: ""
      }
    };
  },
  computed: {
    list() {
      return {
        ...this.list_options,
        data: this.tipo_investimento,
        foot_data: this.tipo_investimento_total
      };
    }
  }
};
</script>
