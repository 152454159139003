<template>
  <KTCard :page="page">
    <template #body>
      <KTTable
        :fields="list.columns"
        :items="list.data"
        :primaryKey="list.primaryKey"
        :sortBy="list.sortBy"
        :paginated="false"
        :searchable="false"
      >
        <template #custom-foot>
          <tr class="bg-primary text-white" v-if="list.foot_data[0]">
            <td>Total ({{ list.foot_data[0].simbolo_moeda }})</td>
            <td></td>
            <td class="text-right">
              {{ formatCurrency(list.foot_data[0].valor_investimento_fixo) }}
            </td>
            <td class="text-right">
              {{ formatCurrency(list.foot_data[0].valor_investimento_pre) }}
            </td>
            <td class="text-right">
              {{ formatCurrency(list.foot_data[0].valor_total) }}
            </td>
            <td class="text-right">-</td>
          </tr>
          <tr class="bg-primary text-white" v-if="list.foot_data[0]">
            <td>Total (%)</td>
            <td></td>
            <td class="text-right">
              {{ formatNumber(list.foot_data[0].percentual_investimento_fixo) }}
            </td>
            <td class="text-right">
              {{ formatNumber(list.foot_data[0].percentual_investimento_pre) }}
            </td>
            <td class="text-right">-</td>
            <td class="text-right">-</td>
          </tr>
        </template>
      </KTTable>
    </template>
  </KTCard>
</template>

<script>
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipResumoInvestimentoFonteInvestimento",
  props: {
    fonte_investimento: {
      type: Array,
      required: true
    },
    fonte_investimento_total: {
      type: Array,
      required: true
    }
  },
  mixins: [HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-dollar-sign",
        title: "Investimento Fixo e Pré-Operacional por Fonte de Recurso"
      },
      list_options: {
        columns: [
          {
            key: "descricao_fonte_financiamento",
            label: "Fonte",
            sortable: false
          },
          {
            key: "simbolo_moeda",
            label: "Moeda",
            sortable: false,
            tdClass: "text-center"
          },
          {
            key: "valor_investimento_fixo",
            label: "Investimento Fixo",
            sortable: false,
            tdClass: "text-right",
            formatter: "currency"
          },
          {
            key: "valor_investimento_pre",
            label: "Investimento Pré-Operacional",
            sortable: false,
            tdClass: "text-right",
            formatter: "currency"
          },
          {
            key: "valor_total",
            label: "Total",
            sortable: false,
            tdClass: "text-right",
            formatter: "currency"
          },
          {
            key: "percentual_total",
            label: "%",
            sortable: false,
            tdClass: "text-right",
            formatter: "number"
          }
        ],
        primaryKey: "fonte_financiamento",
        sortBy: ""
      }
    };
  },
  computed: {
    list() {
      return {
        ...this.list_options,
        data: this.fonte_investimento,
        foot_data: this.fonte_investimento_total
      };
    }
  }
};
</script>
