<template>
  <div>
    <KTCard :page="page" class="mb-8" />
    <ResumoTipo
      class="mb-8"
      :tipo_investimento="tables.tipo_investimento"
      :tipo_investimento_total="tables.tipo_investimento_total"
    />
    <ResumoFonte
      class="mb-8"
      :fonte_investimento="tables.fonte_investimento"
      :fonte_investimento_total="tables.fonte_investimento_total"
    />
    <ResumoDepreciacao
      :depreciacao="tables.depreciacao"
      :depreciacao_total="tables.depreciacao_total"
    />
  </div>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";
import ResumoTipo from "@/view/pages/pip/resumo_investimento/PipResumoInvestimentoTipoInvestimento.vue";
import ResumoFonte from "@/view/pages/pip/resumo_investimento/PipResumoInvestimentoFonteInvestimento.vue";
import ResumoDepreciacao from "@/view/pages/pip/resumo_investimento/PipResumoInvestimentoDepreciacao.vue";

export default {
  name: "PipResumoInvestimentoList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  components: {
    ResumoTipo,
    ResumoFonte,
    ResumoDepreciacao
  },
  data() {
    return {
      page: {
        icon: "la la-dollar-sign",
        title: "Resumo dos Investimentos",
        description: `Os Quadros abaixo apresentam o investimento fixo e
          pré-operacional por Tipo e por Fonte de Recurso. Na sequência,
          o resumo da depreciação dos investimentos por mês e por ano.
          Aqui não existem informações a serem preenchidas.`
      },
      tables: {
        tipo_investimento: [],
        tipo_investimento_total: [],
        fonte_investimento: [],
        fonte_investimento_total: [],
        depreciacao: [],
        depreciacao_total: []
      },
      tipo_plano_investimento: null
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/resumo_investimento`;
    },
    manual() {
      return {
        session: "resumo_investimento",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.tables.tipo_investimento = res[R_GETLIST].tipo_investimento;
        this.tables.tipo_investimento_total =
          res[R_GETLIST].tipo_investimento_total;
        this.tables.fonte_investimento = res[R_GETLIST].fonte_investimento;
        this.tables.fonte_investimento_total =
          res[R_GETLIST].fonte_investimento_total;
        this.tables.depreciacao = res[R_GETLIST].depreciacao;
        this.tables.depreciacao_total = res[R_GETLIST].depreciacao_total;
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
